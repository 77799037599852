<template>
    <div class="contractOrder">
        <!-- <div class="title">签约订单</div> -->
        <!-- 顶部操作栏 -->
        <div class="headBox">
            <div>
                <el-button type="primary" icon="el-icon-plus" size="mini" @click="addOrder()">创建方案</el-button>
                <el-button type="primary" icon="el-icon-success" size="mini" @click="Refresh()">刷新列表</el-button>
            </div>
            
            <div class="right">
                <el-input placeholder="输入订单号" clearable v-model="id" size="mini" @input="getData">
                    <template slot="prepend">订单号:</template>
                </el-input>
                <el-input placeholder="输入姓名" clearable v-model="customerName" size="mini" @input="getData">
                    <template slot="prepend">姓名:</template>
                </el-input>
                <div class="select">
                    <div class="selectTit">支付平台:</div>
                    <el-select
                        v-model="paymentPlatformType"
                        clearable
                        multiple 
                        placeholder="请选择"
                        size="mini"
                        @change="getData"
                    >
                        <el-option
                            v-for="item in platformOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                
            </div>
        </div>
        <!-- 表格栏 -->
        <table-list
            :ButtonWidth="180"
            :tableData="tableData"
            :colrs="colrs"
            :total="total"
            :pagesize="pagesize"
            :pageIndex="pageIndex"
            :selection="$route.query.recovery == 0"
            @currentChange="currentChange"
            @handleSizeChange="handleSizeChange"
        >
            <template #TableItem="scope">
                <span v-if="scope.colr.prop == 'isStage'">
                    {{ handSigningType(scope.row[scope.colr.prop]) }}
                </span>
                <span
                    v-else-if="
                        scope.colr.prop == 'createTime' ||
                        scope.colr.prop == 'signingCompleteTime' ||
                        scope.colr.prop == 'settlementCompleteTime'
                    "
                    >{{
                        new Date(scope.row[scope.colr.prop]) != "Invalid Date"
                            ? new Date(scope.row[scope.colr.prop]).format("yyyy-MM-dd hh:mm")
                            : ""
                    }}</span
                >
                <span v-else-if="scope.colr.prop == 'signingStatus'">未签约</span>
                <span v-else-if="scope.colr.prop == 'signingAmount'">{{ scope.row[scope.colr.prop] | numberToCurrency(2) }}</span>
                <span v-else-if="scope.colr.prop == 'contractOrderId'">
                    <span style="color: #409eff; cursor: pointer" @click="check(scope.row)">{{
                        scope.row[scope.colr.prop]
                    }}</span>
                </span>
            </template>
            <template #Button="scope">
                <el-button size="small" type="text" @click="check(scope.row)">查看</el-button>
                <el-button size="small" type="text" @click="edit(scope.row)">编辑</el-button>

                <el-button size="small" type="text" @click="del(scope.row)" style="color: #e44c4c">删除</el-button>
                <el-button size="mini" type="text" style="color: #67c23a" @click="sign(scope.row)">签约</el-button>
            </template>
        </table-list>

        <el-dialog
            title="申请签约订单"
            class="yzmDialog"
            :visible.sync="yzmdialogVisible"
            width="25%"
            top="25vh"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
        >
            <div class="yzmBox">
                <el-form :model="yzmForm" :rules="yzmRules" ref="yzmForm" label-width="100px" class="demo-ruleForm">
                    <div class="tit">请输入手机验证码</div>
                    <el-form-item label="手机号：">
                        <span>+86{{ mobileNumber }}</span>
                    </el-form-item>
                    <el-form-item label="验证码：" prop="yzm">
                        <el-input
                            v-model="yzmForm.yzm"
                            size="small"
                            placeholder="输入验证码"
                            style="width: 160px"
                        ></el-input>
                        <el-button
                            size="mini"
                            type="primary"
                            :disabled="yzmLoading"
                            @click="sendYzm"
                            :loading="yzmLoading"
                        >
                            <span v-if="yzmLoading">{{ codeTimer }}后重新获取</span>
                            <span v-else>获取验证码</span>
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="yzmdialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="signConfirm('yzmForm')">确 认</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import TableList from "../../components/comm/TableList.vue";
import contractOrderApi from "../../api/contractOrderApi";
import checkImg from "../../components/comm/checkImg.vue";
import axios from "axios";
export default {
    name: "contractOrder",
    components: { TableList, checkImg },
    data() {
        return {
            tableData: [],
            colrs: [
                { label: "订单号", prop: "id", width: 230 },
                { label: "客户姓名", prop: "customerName", width: 100 },
                { label: "金额(元)", prop: "signingAmount", width: 160 },
                { label: "支付平台", prop: "paymentPlatformTypeDesc", width: 100 },
                { label: "签约状态", prop: "signingStatus", width: 100 },
                { label: "签约类型", prop: "isStage", width: 100 },
                { label: "结算类型", prop: "settlementType", width: 100 },
                { label: "", prop: "", width: "" }
            ],
            total: 0,
            pagesize: 20,
            pageIndex: 1,

            checkDialogVisible: false,

            checkForm: {},
            search: "",
            file: {},
            frontImageUrl: "",
            backImageUrl: "",

            showbigImg: false,
            bigImgSrc: "",

            saveLoading: false,

            customerName: "",
            id: "",
            signingStatusOptions: [
                {
                    label: "未签约",
                    value: 0
                },
                {
                    label: "已签约",
                    value: 1
                },
                {
                    label: "签约中",
                    value: 2
                },
                {
                    label: "已解约",
                    value: 3
                }
            ],
            signingStatus: "",
            signingTypeOptions: [
                {
                    label: "单笔",
                    value: 0
                },
                {
                    label: "分期",
                    value: 1
                }
            ],
            signingType: "",

            yzmdialogVisible: false,
            signRow: {},
            yzm: "",
            yzmLoading: false,
            codeTimer: 60,
            yzmForm: {},
            yzmRules: {
                yzm: [
                    { required: true, message: "未输入验证码", trigger: "blur" },
                    { validator: this.validateYzm, trigger: "blur" }
                ]
            },

            mobileNumber: "",

            platformOptions: [
                {
                    label:'通联支付',
                    value:'TLT'
                },
                {
                    label:'汇付宝',
                    value:'HEEPAY'
                },
            ],
            paymentPlatformType: ""
        };
    },

    mounted() {
        document.title = "代扣系统-签约订单";
        this.getData();
    },
    methods: {
        getData() {
           
            contractOrderApi
                .orderList({
                    pageSize: this.pagesize,
                    pageNumber: this.pageIndex,
                    customerName: this.customerName || null,
                    id: this.id || null,
                    signingStatus: this.signingStatus || null,
                    signingType: this.signingType || null,
                    orderType: "SIGNING"
                })
                .then(res => {
                    if (res.success) {
                        this.tableData = res.data.records;
                        this.total = res.data.total;
                       
                    }
                });
        },
        Refresh(){
            this.pageIndex = 1;
            this.getData();
        },
        handSigningType(status) {
            switch (status) {
                case true:
                    return "分期";
                case false:
                    return "单笔";
            }
        },
        handStatus(status) {
            switch (status) {
                case 0:
                    return "未签约";
                case 1:
                    return "已签约";
                case 2:
                    return "签约中";
                case 3:
                    return "已解约";
            }
        },
        handleClose() {
            this.addDialogVisible = false;
        },
        checkhandleClose() {
            this.checkDialogVisible = false;
        },
        addOrder() {
            this.$router.push({ name: "/contractOrder/addContractOrder" });
        },

        check(row) {
            this.$router.push({
                name: "/contractOrder/orderDetails",
                query: { id: row.id, platform: row.paymentPlatformType }
            });
        },
        edit(row) {
            this.$router.push({ name: "/contractOrder/addContractOrder", query: { id: row.id } });
        },
        del(row) {
            this.$confirm("是否确认删除订单号为 " + row.id + " 的订单?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                        contractOrderApi
                            .delOrder({
                                id: row.id
                            })
                            .then(res => {
                                if (res.success) {
                                    this.$message({
                                        type: "success",
                                        message: "删除成功"
                                    });
                                    this.getData();
                                } else {
                                    this.$message({
                                        type: "warning",
                                        message: "删除失败"
                                    });
                                }
                            });
                  
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除"
                    });
                });
        },
        sign(row) {
            this.$confirm("确认签约?", "", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                   

                    if (row.paymentPlatformType == "TLT") {
                        this.signRow = row;
                        this.mobileNumber = row.phoneNumber;
                        this.yzmdialogVisible = true;
                    } else {
                        contractOrderApi
                            .applySigning({
                                id: row.id
                            })
                            .then(res => {
                                if (res.success) {                                    
                                    let a = document.createElement("a");
                                    a.href = res.data;
                                    a.target = "_blank";
                                    a.click();
                                    a.remove();
                                }
                            });
                    }
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消签约"
                    });
                });
        },

        sendYzm() {
            this.codeTimer = 59;
            this.yzmLoading = true;
            window.clearInterval(timer);
            var timer = window.setInterval(() => {
                this.codeTimer--;
                if (this.codeTimer < 1) {
                    this.yzmLoading = false;
                    window.clearInterval(timer);
                }
            }, 1000);
            contractOrderApi
                .applySigning({
                    id: this.signRow.id
                })
                .then(res => {
                    if (res.success) {
                        this.$message({
                            type: "success",
                            message: "发送成功"
                        });
                    } else {
                        this.$message({
                            type: "info",
                            message: res.data.message
                        });
                    }
                });
        },

        signConfirm(yzmForm) {
            this.$refs[yzmForm].validate(valid => {
                if (valid) {
                    contractOrderApi
                        .doSigning({
                            id: this.signRow.id,
                            verifyCode: this.yzmForm.yzm
                        })
                        .then(res => {
                            if (res.success) {
                                this.yzmdialogVisible = false;
                                this.$message({
                                    type: "success",
                                    message: "签约成功"
                                });
                                this.getData();
                            } else {
                                this.$message({
                                    type: "info",
                                    message: res.data.message
                                });
                            }
                        });
                } else {
                    return false;
                }
            });
        },

        validateMobile(rule, value, callback) {
            const reg = /^1[3456789]\d{9}$/;
            if (value === "") {
                callback(new Error("未输入银行预留手机号码"));
            } else if (!reg.test(value)) {
                callback(new Error("未输入正确的手机号码"));
            } else {
                callback();
            }
        },
        validateIdNumber(rule, value, callback) {
            if (!value) {
                callback(new Error("未输入身份证号"));
            } else {
                const reg = /^\w{18}$/;
                if (!reg.test(value)) {
                    callback(new Error("未输入正确的身份证号码"));
                } else {
                    callback();
                }
            }
        },
        validateBankCardNumber(rule, value, callback) {
            if (!value) {
                callback(new Error("未输入银行卡号"));
            } else {
                const reg = /^\d{16,19}$/;
                if (!reg.test(value)) {
                    callback(new Error("未输入正确的银行卡号"));
                } else {
                    callback();
                }
            }
        },
        validatestageNumber(rule, value, callback) {
            if (value === "") {
                callback(new Error("未输入分期数"));
            } else {
                const reg = /^[0-9]*$/;
                if (!reg.test(value)) {
                    callback(new Error("未输入整数"));
                } else {
                    callback();
                }
            }
        },
        handleInput(value) {
            const cleanValue = value.replace(/[^0-9.]/g, "");
            const validValue = cleanValue.split(".").reduce((acc, part, index, array) => {
                if (index === 0) {
                    return acc + part;
                } else if (index === 1 && array[0] !== "") {
                    return acc + "." + part;
                }
                return acc;
            }, "");
            this.form.signingAmount = validValue;
        },
        fronthandleAvatarSuccess(res, file) {
            this.frontImageUrl = URL.createObjectURL(file.raw);
        },
        backhandleAvatarSuccess(res, file) {
            this.backImageUrl = URL.createObjectURL(file.raw);
        },
        frontbeforeAvatarUpload(file) {
            this.form.idCardFrontImage = file;
            this.frontImageUrl = URL.createObjectURL(file);
            if (this.form.idCardFrontImagePath != "") {
                this.$delete(this.form, "idCardFrontImagePath");
            }
            return false;
        },
        backbeforeAvatarUpload(file) {
            this.form.idCardBackImage = file;
            this.backImageUrl = URL.createObjectURL(file);
            if (this.form.idCardBackImagePath != "") {
                this.$delete(this.form, "idCardBackImagePath");
            }
            return false;
        },
        currentChange(pageIndex) {
            this.pageIndex = pageIndex;
            this.getData();
        },
        handleSizeChange(pageSize) {
            this.pageIndex = 1;
            this.pagesize = pageSize;
            this.getData();
        },
        handleVisibilityChange() {
            this.pageVisibility = document.visibilityState;
            if (this.pageVisibility === "visible") {
                this.getData();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.contractOrder {
    background: #ffffff;
    padding: 20px;
    border-radius: 4px;
    height: 89vh;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    .title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 30px;
    }
    .headBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        h3 {
            font-size: 18px;
            font-weight: 500;
            color: #222222;
            line-height: 1;
        }
        .right {
            display: flex;
            align-items: center;
            gap: 10px;
            .select {
                display: flex;
                align-items: center;
                .selectTit {
                    background-color: #f5f7fa;
                    color: #909399;
                    vertical-align: middle;
                    display: table-cell;
                    position: relative;
                    border: 1px solid #dcdfe6;
                    border-right: 0;
                    border-radius: 4px 0 0 4px;
                    padding: 0 20px;
                    height: 26px;
                    line-height: 26px;
                    white-space: nowrap;
                }
                .el-select {
                    width: 180px;
                    /deep/.el-input__inner {
                        border-radius: 0 4px 4px 0;
                    }
                }
            }
        }
    }

    .elTag {
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 320px;
        .elTagSpan {
            display: flex;
            align-items: center;
            img {
                width: 12px;
                height: 12px;
                margin-right: 10px;
            }
        }
    }

    /deep/.el-table .cell {
        line-height: normal !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    /deep/ .el-button--text {
        height: 16px;
        padding: 0;
    }
    .header_tit {
        height: 40px;
        line-height: 44px;
        background: rgb(241, 245, 249);
        font-weight: 700;
        font-size: 16px;
        padding-left: 10px;
        margin-bottom: 20px;
    }

    .infoBox {
        .infoItem {
            border-bottom: 1px solid #eee;
            padding: 0 10px 4px 10px;
            margin: 0 10px 20px;
            display: flex;
            justify-content: space-between;
            &:last-child {
                border: 0;
            }
            .tit {
                font-weight: 700;
            }
            .zjz {
                margin-left: 50px;
                margin-top: 5px;
                cursor: pointer;
                display: flex;
                gap: 10px;
                .img {
                    width: 120px;
                    height: 120px;
                    border: 1px dashed #d9d9d9;
                    margin-top: 15px;
                    &:first-child {
                        margin-bottom: 15px;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .zjzInfo {
            display: block;
            margin: 0 10px 15px;
        }
    }

    .form {
        /deep/ .el-input {
            width: 320px;
        }
        .el-form-item {
            padding: 0 20px 0 30px;
            margin-bottom: 15px;
        }
    }
    .yzmBox {
        .tit {
            font-weight: 600;
            margin: 0 0 10px 32px;
        }
        .el-input {
            margin-right: 10px;
        }
        .el-form-item {
            margin-bottom: 5px;
        }
    }
}

@media (max-width: 600px) {
    .headBox {
        flex-wrap: wrap;
        gap: 16px;
    }
    .right {
        flex-wrap: wrap;
        .el-input {
            width: 272px;
        }
    }

    /deep/ .el-input-group__prepend {
        min-width: 52px;
        text-align: center;
    }
}
</style>

